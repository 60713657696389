// SupportView.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm';

const stripePromise = loadStripe(`${process.env.STRIPE_PUBLISHABLE_KEY}`);

const SupportContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #141417;
  padding: 20px;
  box-sizing: border-box;
`;

const SupportBox = styled.div`
  background-color: #1b1b1f;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const Heading = styled.h1`
  color: #fff;
  margin-bottom: 20px;
`;

const SelectionRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

const Circle = styled.div<{ isSelected: boolean }>`
  width: 50px;
  height: 50px;
  background-color: ${({ isSelected }) => (isSelected ? '#0bb659' : '#28282e')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0bb659;
  }
`;

const SquareInput = styled.input<{ isSelected: boolean; isPresetValue: boolean }>`
  width: 50px;
  height: 50px;
  background-color: #28282e;
  border: ${({ isSelected }) => (isSelected ? '2px solid #0bb659' : 'none')};
  border-radius: 8px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  opacity: ${({ isPresetValue }) => (isPresetValue ? 0.5 : 1)};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: none;
    border: 2px solid #0bb659;
  }

  ::placeholder {
    font-size: 12px;
  }
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: #28282e;
  border: none;
  border-radius: 5px;
  color: #fff;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border: 1px solid #0bb659;
  }

  ::placeholder {
    font-size: 12px;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: #28282e;
  border: none;
  border-radius: 5px;
  color: #fff;
  resize: none;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border: 1px solid #0bb659;
  }

  ::placeholder {
    font-size: 12px;
  }
`;

const SupportView: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState<number | string>(10);
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [isSquareInputSelected, setIsSquareInputSelected] = useState(false);

  const handleCircleClick = (value: number) => {
    setSelectedValue(value);
    setIsSquareInputSelected(false);
  };

  const handleSquareChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setSelectedValue(value === '' ? '' : parseInt(value, 10));
  };

  const handleSquareBlur = () => {
    if (typeof selectedValue === 'number' && selectedValue < 4) {
      setSelectedValue(4);
    }
  };

  const handleSquareFocus = () => {
    setIsSquareInputSelected(true);
  };

  const isPresetValue = [3, 10, 25].includes(Number(selectedValue));

  return (
    <SupportContainer>
      <SupportBox>
        <Heading>Help us cool off</Heading>
        <SelectionRow>
          <Circle isSelected={selectedValue === 3} onClick={() => handleCircleClick(3)}>
            3
          </Circle>
          <Circle isSelected={selectedValue === 10} onClick={() => handleCircleClick(10)}>
            10
          </Circle>
          <Circle isSelected={selectedValue === 25} onClick={() => handleCircleClick(25)}>
            25
          </Circle>
          <SquareInput
            type="text"
            value={typeof selectedValue === 'number' ? selectedValue : ''}
            placeholder="Other"
            onChange={handleSquareChange}
            isSelected={isSquareInputSelected}
            isPresetValue={isPresetValue}
            onFocus={handleSquareFocus}
            onBlur={handleSquareBlur}
          />
        </SelectionRow>
        <InputField
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextArea
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={5}
        />
        <Elements stripe={stripePromise}>
        <PaymentForm amount={Number(selectedValue)} />
        </Elements>
      </SupportBox>
    </SupportContainer>
  );
};

export default SupportView;
