// AboutView.tsx
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FaEnvelope, FaHeart } from 'react-icons/fa';

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  padding: 20px;
  background-color: #1B1B1F;
  color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  margin: auto; /* Center horizontally */
  position: absolute; /* Ensure absolute positioning */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Correct the offset caused by absolute positioning */
  

  @media (max-width: 768px) {
    position: relative; /* Change to relative positioning for small screens */
    top: auto; /* Reset top positioning for small screens */
    left: auto; /* Reset left positioning for small screens */
    transform: none; /* Remove transform for small screens */
    margin: 20px; /* Add margin for spacing */
  }
`;

const AboutImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 200px; /* Set max height to make the image smaller */
  border-radius: 10px;
  margin-bottom: 20px;
`;

const AboutText = styled.p`
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.2rem;
`;

const LinksRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;

const LinkText = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: #0BB659;
  display: flex;
  align-items: center;

  &:hover {
    color: #0eed74;
    
  }

  svg {
    margin-right: 5px;
  }
`;

const AboutView: React.FC = () => {
  const navigate = useNavigate();

  return (
    <AboutContainer>
      <AboutImage src="https://via.placeholder.com/600x400" alt="About Us" />
      <AboutText>
        Welcome to MLP. We are committed to providing the best experience for our users. Our mission is to deliver high-quality services and features that meet your needs.
        We continuously strive to improve our platform, ensuring that it remains user-friendly, efficient, and up-to-date with the latest advancements in technology.
        Our dedicated team works tirelessly to address any issues and implement new features based on user feedback. Thank you for choosing MLP, and we look forward to serving you.
      </AboutText>
      <LinksRow>
        <LinkText onClick={() => navigate('/contact')}>
          <FaEnvelope />
          Contact
        </LinkText>
        <LinkText onClick={() => navigate('/support')}>
          <FaHeart />
          Support
        </LinkText>
      </LinksRow>
    </AboutContainer>
  );
};

export default AboutView;
