//MobileCoupon.css.ts
import styled from 'styled-components';
import { FaTrash } from 'react-icons/fa';

export const Container = styled.div`
  width: 100%;
  background-color: #28282E;
  color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-height: 700px; /* Adjust as needed */
  overflow-y: auto;
`;

export const Title = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 10px;
  text-align: center;  /* Center-aligns the text */
  width: 100%;         /* Ensure it takes full width of its container */
`;

export const EntriesContainer = styled.ul`
  height: 350px;
  overflow-y: auto;
  margin-bottom: 20px;
  background-color: #34343c;
  padding: 0;
  list-style-type: none;
  padding: 5px;
`;

export const EntryBox = styled.li`
  position: relative;
  margin-bottom: 10px;
  padding: 5px;
  background-color: #1B1B1F;
  border-radius: 4px;
  margin-bottom: 5px;

  div {
    margin-bottom: 5px;
  }
`;

export const RemoveButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px 5px;
  background-color: #101013;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ff1a1a;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    padding: 10px 20px;
    background-color: #218838;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
    width: 100%; /* Make the button take up more space */

    &:hover {
      background-color: #0BB659;
    }
  }
`;

export const ClearButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ff4d4d;
  height: 1px;
  margin-bottom: 15px;

  &:hover {
    color: #ff1a1a;
  }
`;

export const TrashIcon = styled(FaTrash)`
  margin-right: 5px;
`;

export const SuccessMessage = styled.div`
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 10px;
`;


export const FloatingBarContainer = styled.div`
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(135deg, #3a3a43, #5f5f6d); /* Gradient background */
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: linear-gradient(135deg, #5f5f6d, #787887); /* Adjust hover gradient */
  }

  span {
    margin-left: 10px;
  }

  @media (min-width: 1000px) {
    display: none;
  }
`;

export const ExpandedCouponContainer = styled.div`
  position: fixed;
  bottom: 70px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: #141417; 
  color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 500px;
  overflow-y: auto;
  width: 350px;
  z-index: 1000; /* Ensure it's on top of other elements */

  @media (min-width: 1000px) {
    display: none;
  }
`;

export const EntryCountBox = styled.div`
  background-color: #0BB659;
  color: white;
  padding: 5px 10px;
  border-radius: 50%;
  margin-left: 10px;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    color: white;
  }
`;

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 150px 20px;
   
  
  
`;

export const SuccessCircle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #0BB659;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
  margin-bottom: 20px;
`;

export const SuccessText = styled.p`
  color: #0BB659;
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

export const ContinueButton = styled.button`
  padding: 10px 20px;
  background-color: #0BB659;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;

  &:hover {
    background-color: #0aa24d;
  }
`;