import styled from 'styled-components';
import { FaTrash, FaClipboard } from 'react-icons/fa';

export const CouponContainer = styled.div`
  width: 100%;
  width: 287px;
  background-color: #28282E;
  color: white;
  height: 450px; 
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 20px;
  flex-shrink: 0;
  margin: 0 20px; /* Ensure space on both sides */
  margin-top: 270px;
  
  h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    text-align: center;
  }

  p {
    margin-bottom: 20px;
    word-wrap: break-word;
  }

  @media (max-width: 1200px) {
    max-width: 280px;
    padding: 8px;
  }

  @media (max-width: 1000px) {
    max-width: 260px;
    padding: 6px;
  }

  @media (max-width: 800px) {
    max-width: 240px;
    padding: 4px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const CouponEntriesContainer = styled.ul`
  height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
  background-color: #34343c;
  padding: 0;
  list-style-type: none;
  padding: 5px;
`;

export const CouponEntryBox = styled.li`
  position: relative;
  margin-bottom: 10px;
  padding: 5px;
  background-color: #1B1B1F;
  border-radius: 4px;
  margin-bottom: 5px;

  div {
    margin-bottom: 5px;
  }
`;

export const RemoveButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px 5px;
  background-color: #101013;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ff1a1a;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    padding: 10px 20px;
    background-color: #218838;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
    width: 100%; /* Make the button take up more space */

    &:hover {
      background-color: #0BB659;
    }
  }
`;

export const ClearButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ff4d4d;
  height: 1px;
  margin-bottom: 15px;

  &:hover {
    color: #ff1a1a;
  }
`;

export const TrashIcon = styled(FaTrash)`
  margin-right: 5px;
`;

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const SuccessCircle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #0BB659;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
  margin-bottom: 20px;
`;

export const SuccessText = styled.p`
  color: #0BB659;
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

export const ContinueButton = styled.button`
  padding: 10px 20px;
  background-color: #0BB659;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;

  &:hover {
    background-color: #0aa24d;
  }
`;

export const EmptyMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ClipboardIcon = styled(FaClipboard)`
  font-size: 48px;
  margin-bottom: 10px;
  color: #B8B8B9;
`;

export const EmptyText = styled.p`
  color: #B8B8B9;
  font-size: 1.2rem;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  width: 300px;
  background-color: #28282E;
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalSuccessCircle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #0BB659;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
  margin-bottom: 20px;
`;

export const ModalSuccessText = styled.p`
  color: white;
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

export const ModalContinueButton = styled.button`
  padding: 10px 20px;
  background-color: #0BB659;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;

  &:hover {
    background-color: #0aa24d;
  }
`;