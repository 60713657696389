import React from 'react';
import {
  ModalOverlay,
  ModalContent,
  ModalSuccessCircle,
  ModalSuccessText,
  ModalContinueButton,
} from './CouponContainer.css'; // Ensure this path matches where you store the styles

interface SuccessModalProps {
  onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ onClose }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <ModalSuccessCircle>✔️</ModalSuccessCircle>
        <ModalSuccessText>Picks Saved</ModalSuccessText>
        <ModalContinueButton onClick={onClose}>Continue</ModalContinueButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default SuccessModal;
