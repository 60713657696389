// Carousel.tsx
import React, { useEffect, useState, useRef } from 'react';
import { useDrag } from '@use-gesture/react';
import styled from 'styled-components';

const CarouselContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  overflow: hidden;
  position: relative;
`;

const CarouselWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.5s ease-in-out;
`;

const CarouselItem = styled.div`
  flex: 0 0 33.33%;
  max-width: 33.33%;
  box-sizing: border-box;
  padding: 10px;

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  @media (max-width: 1024px) {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  @media (max-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (max-width: 600px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;

export const ArrowButton = styled.button`
  background-color: #333;
  border: none;
  color: white;
  padding: 8px;
  cursor: pointer;
  font-size: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #555;
  }

  &:disabled {
    background-color: #777;
    cursor: not-allowed;
  }
`;

interface CarouselProps {
  images: string[];
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const length = images.length;
  const isTransitioning = useRef(false);
  const carouselRef = useRef<HTMLDivElement>(null);

  const handlePrevClick = () => {
    if (!isTransitioning.current) {
      isTransitioning.current = true;
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (!isTransitioning.current) {
      isTransitioning.current = true;
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const bind = useDrag(({ swipe: [swipeX], direction: [dirX], distance: [distX], velocity: [velX] }) => {
    if (distX > 100 && velX > 0.5) {
      if (swipeX === 1 || dirX === 1) handlePrevClick();
      if (swipeX === -1 || dirX === -1) handleNextClick();
    }
  });

  useEffect(() => {
    const transitionEndHandler = () => {
      isTransitioning.current = false;
      if (currentIndex === length) {
        carouselRef.current!.style.transition = 'none';
        setCurrentIndex(0);
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            carouselRef.current!.style.transition = 'transform 0.5s ease-in-out';
            carouselRef.current!.style.transform = `translateX(-${100 / 3}%)`;
          });
        });
      } else if (currentIndex === -1) {
        carouselRef.current!.style.transition = 'none';
        setCurrentIndex(length - 1);
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            carouselRef.current!.style.transition = 'transform 0.5s ease-in-out';
            carouselRef.current!.style.transform = `translateX(-${(length - 1) * (100 / 3)}%)`;
          });
        });
      }
    };

    const carouselElem = carouselRef.current;
    carouselElem!.addEventListener('transitionend', transitionEndHandler);

    return () => {
      carouselElem!.removeEventListener('transitionend', transitionEndHandler);
    };
  }, [currentIndex, length]);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.style.transform = `translateX(-${(currentIndex + 1) * (100 / 3)}%)`;
    }
  }, [currentIndex]);

  useEffect(() => {
    const autoScroll = setInterval(() => {
      if (currentIndex === length - 1) {
        // Pause before looping back to the start
        setTimeout(() => {
          handleNextClick();
        }, 1000); // 1 second pause
      } else {
        handleNextClick();
      }
    }, 3000); // Auto-scroll every 3 seconds

    return () => clearInterval(autoScroll);
  }, [currentIndex, length]);

  return (
    <div>
      <CarouselContainer>
        <CarouselWrapper ref={carouselRef} {...bind()}>
          <CarouselItem key="clone-last">
            <img src={images[length - 1]} alt={`Slide ${length}`} />
          </CarouselItem>
          {images.map((image, index) => (
            <CarouselItem key={index}>
              <img src={image} alt={`Slide ${index + 1}`} />
            </CarouselItem>
          ))}
          <CarouselItem key="clone-first">
            <img src={images[0]} alt="Slide 1" />
          </CarouselItem>
        </CarouselWrapper>
      </CarouselContainer>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <ArrowButton onClick={handlePrevClick}>❮</ArrowButton>
        <ArrowButton onClick={handleNextClick}>❯</ArrowButton>
      </div>
    </div>
  );
};

export default Carousel;
