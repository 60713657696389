import React, { useEffect, useState } from 'react';
import { StyledFinalMatch } from './FinalMatch.css';
import {
  PickItem,
  TeamLogosContainerHome,
  TeamLogos,
  TeamAcronyms,
  PickDetails,
  PickStatus,
  WeekText,
 
} from './UserView.css';

export interface FinalMatchProps {
  eventId: string;
  league: string;
  teamHome: Team;
  teamAway: Team;
  week: number;
  homeScore: number;
  awayScore: number;
  userPicks: UserPick[];
}

export interface Team {
  teamName: string;
  shortName: string;
  image: string;
  spreadHome: number | null;
  spreadAway: number | null;
  overUnderTotal: number | null;
}

export interface UserPick {
  eventId: string;
  column: string;
  id: string;
  value: string;
  type: string;
  teamAway: string;
  teamHome: string;
  isWin: string | null;
}

function FinalMatch(props: FinalMatchProps): JSX.Element {
  const [showPicksDialog, setShowPicksDialog] = useState(false); // State to manage dialog visibility
  const formatSpread = (spread: number | null) => {
    if (spread === null) return 'N/A';
    return spread > 0 ? `+${spread}` : spread.toString();
  };

  // Determine if the home and away teams covered the spread
  const homeSpreadResult = props.homeScore + (props.teamHome.spreadHome || 0) - props.awayScore;
  const awaySpreadResult = props.awayScore + (props.teamAway.spreadAway || 0) - props.homeScore;

  const didHomeCoverSpread = homeSpreadResult > 0;
  const didAwayCoverSpread = awaySpreadResult > 0;
  const isSpreadPush = homeSpreadResult === 0;

  // Determine if the total score was over, under, or a push
  const totalScore = props.homeScore + props.awayScore;
  const isOverCorrect = totalScore > (props.teamHome.overUnderTotal || 0);
  const isUnderCorrect = totalScore < (props.teamHome.overUnderTotal || 0);
  const isTotalPush = totalScore === (props.teamHome.overUnderTotal || 0);

  const scoresAreEqual = props.homeScore === props.awayScore;

   // Provide a default empty array if userPicks is undefined
   const picksCount = props.userPicks ? props.userPicks.length : 0;

   const handlePickCircleClick = () => {
    setShowPicksDialog(true);
  };

  const handleCloseDialog = () => {
    setShowPicksDialog(false);
  };

  
  const getPickDisplay = (pick: UserPick) => {
    if (pick.type === 'spread') {
      // Determine if the id ends with '-spreadHome' or '-spreadAway'
      if (pick.id.endsWith('-spreadHome')) {
        return `${pick.teamHome} ${pick.value}`;
      } else if (pick.id.endsWith('-spreadAway')) {
        return `${pick.teamAway} ${pick.value}`;
      }
    }
  
    if (pick.type === 'total') {
      return `${pick.value}`;
    }
  
    if (pick.type === 'winner') {
      // If it's a winner pick, check if it's for the home or away team based on id
      if (pick.id.endsWith('-homeTeam')) {
        return `${pick.teamHome}`;
      } else if (pick.id.endsWith('-awayTeam')) {
        return `${pick.teamAway}`;
      }
    }
  
    return `${pick.value}`;
  };
  
  
  
  const mapPickStatus = (isWin: string | null) => {
    if (isWin === 'win') return 'win';
    if (isWin === 'loss') return 'lose';
    return 'neutral';
  };


  return (
    <StyledFinalMatch data-eventid={props.eventId}>
       <div className="header-row">
  <h3>Final</h3>
  {picksCount > 0 && (
    <div className="pick-circle" onClick={handlePickCircleClick}>
      {picksCount} Pick{picksCount > 1 ? 's' : ''}
    </div>
  )}
</div>

{/* Dialog Box */}
{showPicksDialog && (
  <div className="dialog-overlay">
    <div className="dialog-content">
      <button className="close-button" onClick={handleCloseDialog}>
        &times;
      </button>
      <h4>Your Picks ({props.userPicks.length})</h4>
      <div className="divider"></div> {/* Divider between header and picks */}
      <div className="picks-container">
        {props.userPicks.map((pick, index) => (
          <PickItem key={index} index={index}>
            <TeamLogosContainerHome>
              <TeamLogos>
                <img src={props.teamAway.image} alt={props.teamAway.shortName} />
                <span>-</span>
                <img src={props.teamHome.image} alt={props.teamHome.shortName} />
              </TeamLogos>
              <TeamAcronyms>
                <div>{props.teamAway.shortName}</div>
                <div>{props.teamHome.shortName}</div>
              </TeamAcronyms>
            </TeamLogosContainerHome>
            <PickDetails>
              <div>{getPickDisplay(pick)}</div>
              <PickStatus status={mapPickStatus(pick.isWin)} />
            </PickDetails>
          </PickItem>
        ))}
      </div>
    </div>
  </div>
)}
     <p className="date">
  {props.week === -3 ? 'Preseason Week 3' : `Week ${props.week}`}</p>
      <div className="button-group">
        <div className="team-row">
          <div className="team-info">
            <div className="team-image-container">
              <img src={props.teamAway.image} alt={props.teamAway.shortName} />
              <span className="team-shortname">{props.teamAway.shortName}</span>
            </div>
            <div className="buttons">
              <div className="hint-button-pair">
                <div className="hint">Score</div>
                <div className="score" style={{ color: scoresAreEqual ? '#FFFFFF' : props.awayScore > props.homeScore ? '#0BB659' : '#FFFFFF' }}>
                  {props.awayScore}
                </div>
              </div>
              <div className="hint-button-pair">
                <div className="hint">Spread</div>
                <button className={`course ${isSpreadPush ? 'push' : didAwayCoverSpread ? 'correct' : ''}`}>
                  <span>{formatSpread(props.teamAway.spreadAway)}</span>
                </button>
              </div>
              <div className="hint-button-pair">
                <div className="hint">Total</div>
                <button className={`course ${isTotalPush ? 'push' : isOverCorrect ? 'correct' : ''}`}>
                  <span>{props.teamAway.overUnderTotal !== null ? `O ${props.teamAway.overUnderTotal}` : 'N/A'}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="team-row">
          <div className="team-info">
            <div className="team-image-container">
              <img src={props.teamHome.image} alt={props.teamHome.shortName} />
              <span className="team-shortname">{props.teamHome.shortName}</span>
            </div>
            <div className="buttons">
              <div className="hint-button-pair">
                <div className="score" style={{ color: scoresAreEqual ? '#FFFFFF' : props.homeScore > props.awayScore ? '#0BB659' : '#FFFFFF' }}>
                  {props.homeScore}
                </div>
              </div>
              <div className="hint-button-pair">
                <button className={`course ${isSpreadPush ? 'push' : didHomeCoverSpread ? 'correct' : ''}`}>
                  <span>{formatSpread(props.teamHome.spreadHome)}</span>
                </button>
              </div>
              <div className="hint-button-pair">
                <button className={`course ${isTotalPush ? 'push' : isUnderCorrect ? 'correct' : ''}`}>
                  <span>{props.teamHome.overUnderTotal !== null ? `U ${props.teamHome.overUnderTotal}` : 'N/A'}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        </div>
    </StyledFinalMatch>
  );
}

export default FinalMatch;
