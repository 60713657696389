import styled from 'styled-components';

export const FloatingBarContainer = styled.div`
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(135deg, #3a3a43, #5f5f6d); /* Gradient background */
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: linear-gradient(135deg, #5f5f6d, #787887); /* Adjust hover gradient */
  }

  span {
    margin-left: 10px;
  }

  @media (min-width: 1000px) {
    display: none;
  }
`;

export const ExpandedCouponContainer = styled.div`
  position: fixed;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #141417; 
  color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 500px;
  overflow-y: auto;
  width: 350px;
  z-index: 1000;
  display: flex;  /* Add flexbox to the parent */
  align-items: center;  /* Center content vertically */
  justify-content: center;  /* Center content horizontally */

  @media (min-width: 1000px) {
    display: none;
  }
`;

export const EntryCountBox = styled.div`
  background-color: #0BB659;
  color: white;
  padding: 5px 10px;
  border-radius: 50%;
  margin-left: 10px;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: rgba(0, 0, 0, 1.0);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;

  svg {
    color: white;
    font-size: 18px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

export const SuccessContainer = styled.div`
  background-color: #28282E;
  display: flex;
  width: 100%;;
  border-radius: 2%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 150px 20px;
`;

export const SuccessCircle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #0BB659;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
  margin-bottom: 20px;
`;

export const SuccessText = styled.p`
  color: #0BB659;
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

export const ContinueButton = styled.button`
  padding: 10px 20px;
  background-color: #0BB659;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;

  &:hover {
    background-color: #0aa24d;
  }
`;
