//Sidebar.tsx
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SidebarContainer, Icon } from './Sidebar.css';

import homeIcon from './assets/icons/home.png';
import boardIcon from './assets/icons/board.png';
import userIcon from './assets/icons/user.png';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const icons = [
    { id: 0, icon: homeIcon, alt: 'Home', path: '/' },
    //{ id: 1, icon: boardIcon, alt: 'Board', path: '/board' },
    { id: 2, icon: userIcon, alt: 'User', path: '/user' },
  ];

  const handleIconClick = (path: string) => {
    navigate(path);
  };

  return (
    <SidebarContainer>
      {icons.map((item) => (
        <Icon
          key={item.id}
          active={location.pathname === item.path}
          onClick={() => handleIconClick(item.path)}
        >
          <img src={item.icon} alt={item.alt} />
        </Icon>
      ))}
    </SidebarContainer>
  );
};

export default Sidebar;
