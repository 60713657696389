import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InputContainer = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #28282E;
`;

const SubmitButton = styled.button`
  background-color: #218838;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0BB659;
  }

  &:disabled {
    background-color: #888;
    cursor: not-allowed;
  }
`;

interface PaymentFormProps {
  amount: number;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ amount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

 const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setError('Stripe.js has not loaded yet.');
      setLoading(false);
      return;
    }

    try {
      // Request a payment intent from your server
      const { data: { clientSecret } } = await axios.post(`${process.env.REACT_APP_API_URL}/create-payment-intent`, { amount });

      // Confirm the payment using the card details provided by the user
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)!,
          billing_details: {
            name: 'Customer Name', // Replace with actual customer name
          },
        },
      });

      if (error) {
        setError(`Payment failed: ${error.message}`);
      } else if (paymentIntent!.status === 'succeeded') {
        setSuccess('Payment successful!');
      } else {
        setError('Payment could not be processed. Please try again.');
      }
    } catch (err) {
      setError('Failed to process payment');
    } finally {
      setLoading(false);
    }
  };

  

  return (
    <Form onSubmit={handleSubmit}>
      <InputContainer>
        <CardElement />
      </InputContainer>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {success && <div style={{ color: 'green' }}>{success}</div>}
      <SubmitButton type="submit" disabled={!stripe || loading}>
        {loading ? 'Processing...' : `Support $${amount}`}
      </SubmitButton>
    </Form>
  );
};

export default PaymentForm;
