import React, { useState } from 'react';
import styled from 'styled-components';

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #1B1B1F;
  color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  margin: 20px auto;

  @media (max-width: 768px) {
    margin: 20px;
    padding: 20px;
  }
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px 0;
  width: calc(100% - 20px); /* Adjust for padding */
  border-radius: 5px;
  border: 1px solid #444;
  background-color: white; /* Light gray color */
  color: black;

  &:focus {
    outline: none;
    border-color: #0BB659; /* Optional: Highlight border on focus */
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  margin: 10px 0;
  width: calc(100% - 20px); /* Adjust for padding */
  height: 100px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: white; /* Light gray color */
  color: black;

  &:focus {
    outline: none;
    border-color: #0BB659; /* Optional: Highlight border on focus */
  }
`;

const Select = styled.select`
  padding: 10px;
  margin: 10px 0;
  width: calc(100% - 20px); /* Adjust for padding */
  border-radius: 5px;
  border: 1px solid #444;
  background-color: white; /* Light gray color */
  color: black;

  &:focus {
    outline: none;
    border-color: #0BB659; /* Optional: Highlight border on focus */
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 20px 0;
  background-color: #0BB659;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }
`;

const ContactView: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [topic, setTopic] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <ContactContainer>
      <h1>Contact Us</h1>
      <form onSubmit={handleSubmit}>
        <Input 
          type="text" 
          placeholder="Name" 
          value={name} 
          onChange={(e) => setName(e.target.value)} 
        />
        <Input 
          type="email" 
          placeholder="Email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
        />
        <Select 
          value={topic} 
          onChange={(e) => setTopic(e.target.value)}
        >
          <option value="" disabled>Select a topic</option>
          <option value="Report an issue">Report an issue</option>
          <option value="Suggest new feature">Suggest new feature</option>
          <option value="General inquiry">General inquiry</option>
          <option value="Other">Other</option>
        </Select>
        <TextArea 
          placeholder="Message" 
          value={message} 
          onChange={(e) => setMessage(e.target.value)} 
        />
        <Button type="submit">Submit</Button>
      </form>
    </ContactContainer>
  );
};

export default ContactView;
