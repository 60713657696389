//LiveMatch.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, addCoupon } from './store';
import { StyledLiveMatch } from './LiveMatch.css';
import {
  PickItem,
  TeamLogosContainerHome,
  TeamLogos,
  TeamAcronyms,
  PickDetails,
  PickStatus,
  WeekText,
 
} from './UserView.css';

export interface LiveMatchProps {
  eventId: string;
  league: string;
  teamHome: Team;
  teamAway: Team;
  userPicks: UserPick[]; 
}

export interface Team {
  teamName: string;
  shortName: string;
  image: string;
  spreadHome: number | null;
  spreadAway: number | null;
  overUnderTotal: number | null;
}

export interface UserPick {
  eventId: string;
  column: string;
  id: string;
  value: string;
  type: string;
  teamAway: string;
  teamHome: string;
  isWin: string | null;
}

function LiveMatch(props: LiveMatchProps): JSX.Element {
  const dispatch = useDispatch();
  const activeButtons = useSelector((state: RootState) => state.coupon.activeButtons[props.eventId] || {});
  const [showPicksDialog, setShowPicksDialog] = useState(false); // State to manage dialog visibility
  const [quarter, setQuarter] = useState<string>('');
  const [timeRemaining, setTimeRemaining] = useState<string>('');
  const [homeScore, setHomeScore] = useState<number>(0);
  const [awayScore, setAwayScore] = useState<number>(0);

  useEffect(() => {
    const ws = new WebSocket(''); // Replace with your server URL ws://localhost:5000

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data._id === props.eventId && data.liveScore) {
        setQuarter(data.liveScore.Quarter);
        setTimeRemaining(data.liveScore.TimeRemaining);
        setHomeScore(data.liveScore.HomeScore);
        setAwayScore(data.liveScore.AwayScore);
      }
    };

    ws.onerror = (err) => {
      console.error('WebSocket error:', err);
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      ws.close();
    };
  }, [props.eventId]);

  const formatSpread = (spread: number | null) => {
    if (spread === null) return 'N/A';
    return spread > 0 ? `+${spread}` : spread.toString();
  };

  const handleButtonClick = (column: string, id: string, value: string, type: string, teamAway: string, teamHome: string) => {
    const spreadValue = type === 'spread' ? `${value}` : value;
    dispatch(addCoupon({ eventId: props.eventId, column, id, value: spreadValue, type, teamAway, teamHome }));
  };

  const getButtonClass = (id: string) => (Object.values(activeButtons).includes(id) ? 'course active' : 'course');

  const formatQuarter = (quarter: string) => {
    switch (quarter) {
      case '1':
        return '1st';
      case '2':
        return '2nd';
      case '3':
        return '3rd';
      case '4':
        return '4th';
      case 'OT':
        return 'Overtime';
      case 'HALF':
        return 'Halftime';
      default:
        return quarter; // fallback in case the value is unexpected
    }
  };

   // Provide a default empty array if userPicks is undefined
   const picksCount = props.userPicks ? props.userPicks.length : 0;
   
   const handlePickCircleClick = () => {
    setShowPicksDialog(true);
  };

  const handleCloseDialog = () => {
    setShowPicksDialog(false);
  };

  
  const getPickDisplay = (pick: UserPick) => {
    if (pick.type === 'spread') {
      // Determine if the id ends with '-spreadHome' or '-spreadAway'
      if (pick.id.endsWith('-spreadHome')) {
        return `${pick.teamHome} ${pick.value}`;
      } else if (pick.id.endsWith('-spreadAway')) {
        return `${pick.teamAway} ${pick.value}`;
      }
    }
  
    if (pick.type === 'total') {
      return `${pick.value}`;
    }
  
    if (pick.type === 'winner') {
      // If it's a winner pick, check if it's for the home or away team based on id
      if (pick.id.endsWith('-homeTeam')) {
        return `${pick.teamHome}`;
      } else if (pick.id.endsWith('-awayTeam')) {
        return `${pick.teamAway}`;
      }
    }
  
    return `${pick.value}`;
  };
  
  const mapPickStatus = (isWin: string | null) => {
    if (isWin === 'win') return 'win';
    if (isWin === 'lose') return 'lose';
    return 'neutral';
  };

  return (
    <StyledLiveMatch data-eventid={props.eventId}>
       <div className="header-row">
  <h3>LIVE</h3>
  {picksCount > 0 && (
    <div className="pick-circle" onClick={handlePickCircleClick}>
      {picksCount} Pick{picksCount > 1 ? 's' : ''}
    </div>
  )}
</div>

{/* Dialog Box */}
{showPicksDialog && (
  <div className="dialog-overlay">
    <div className="dialog-content">
      <button className="close-button" onClick={handleCloseDialog}>
        &times;
      </button>
      <h4>Your Picks ({props.userPicks.length})</h4>
      <div className="divider"></div> {/* Divider between header and picks */}
      <div className="picks-container">
        {props.userPicks.map((pick, index) => (
          <PickItem key={index} index={index}>
            <TeamLogosContainerHome>
              <TeamLogos>
                <img src={props.teamAway.image} alt={props.teamAway.shortName} />
                <span>-</span>
                <img src={props.teamHome.image} alt={props.teamHome.shortName} />
              </TeamLogos>
              <TeamAcronyms>
                <div>{props.teamAway.shortName}</div>
                <div>{props.teamHome.shortName}</div>
              </TeamAcronyms>
            </TeamLogosContainerHome>
            <PickDetails>
              <div>{getPickDisplay(pick)}</div>
              <PickStatus status={mapPickStatus(pick.isWin)} />
            </PickDetails>
          </PickItem>
        ))}
      </div>
    </div>
  </div>
)}
      <p className="date"> {timeRemaining} {quarter !== 'HALF' && '-'} {formatQuarter(quarter)}</p>
      <div className="button-group">
        <div className="team-row">
          <div className="team-info">
            <div className="team-image-container">
              <img src={props.teamAway.image} alt={props.teamAway.shortName} />
              <span className="team-shortname">{props.teamAway.shortName}</span>
            </div>
            <div className="buttons">
              <div className="hint-button-pair">
                <div className="hint">Score</div>
                <div className="score">{awayScore}</div>
              </div>
              <div className="hint-button-pair">
                <div className="hint">Spread</div>
                <button
                  className={getButtonClass(`${props.eventId}-spreadAway`)}
                  onClick={() => handleButtonClick('spread', `${props.eventId}-spreadAway`, formatSpread(props.teamAway.spreadAway), 'spread', props.teamAway.teamName, props.teamHome.teamName)}
                >
                  <span>{formatSpread(props.teamAway.spreadAway)}</span>
                </button>
              </div>
              <div className="hint-button-pair">
                <div className="hint">Total</div>
                <button
                  className={getButtonClass(`${props.eventId}-overTotal`)}
                  onClick={() => handleButtonClick('total', `${props.eventId}-overTotal`, `Over ${props.teamAway.overUnderTotal}`, 'total', props.teamAway.teamName, props.teamHome.teamName)}
                >
                  <span>{props.teamAway.overUnderTotal !== null ? `O ${props.teamAway.overUnderTotal}` : 'N/A'}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="team-row">
          <div className="team-info">
            <div className="team-image-container">
              <img src={props.teamHome.image} alt={props.teamHome.shortName} />
              <span className="team-shortname">{props.teamHome.shortName}</span>
            </div>
            <div className="buttons">
              <div className="hint-button-pair">
                <div className="score">{homeScore}</div>
              </div>
              <div className="hint-button-pair">
                <button
                  className={getButtonClass(`${props.eventId}-spreadHome`)}
                  onClick={() => handleButtonClick('spread', `${props.eventId}-spreadHome`, formatSpread(props.teamHome.spreadHome), 'spread', props.teamAway.teamName, props.teamHome.teamName)}
                >
                  <span>{formatSpread(props.teamHome.spreadHome)}</span>
                </button>
              </div>
              <div className="hint-button-pair">
                <button
                  className={getButtonClass(`${props.eventId}-underTotal`)}
                  onClick={() => handleButtonClick('total', `${props.eventId}-underTotal`, `Under ${props.teamHome.overUnderTotal}`, 'total', props.teamAway.teamName, props.teamHome.teamName)}
                >
                  <span>{props.teamHome.overUnderTotal !== null ? `U ${props.teamHome.overUnderTotal}` : 'N/A'}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledLiveMatch>
  );
}

export default LiveMatch;
