// App.tsx
import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ClerkProvider, SignIn, SignUp } from '@clerk/clerk-react';
import { AppContainer, MainContentContainer } from './App.css';
import TopNavigation from './TopNavigation';
import Sidebar from './Sidebar';
import store, { RootState } from './store';
import HomeView from './homeView';
import BoardView from './boardView';
import UserView from './userView';
import SettingsView from './SettingsView'; // Import SettingsView
import ContactView from './ContactView'; // Import ContactView
import AboutView from './AboutView'; // Import AboutView
import SupportView from './SupportView'; // Import SupportView
import { addCoupon } from './store';
import FloatingActionBar from './FloatingActionBar';
import WebSocketTest from './WebSocketTest'; 
import LiveScore from './LiveScore'; 

const clerkPublishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY || '';

const AppContent: React.FC = () => {
  const dispatch = useDispatch();
  const couponEntries = useSelector((state: RootState) => state.coupon.coupon);
  const location = useLocation();

  useEffect(() => {
    const storedCouponEntries = localStorage.getItem('couponEntries');
    if (storedCouponEntries) {
      const couponEntries = JSON.parse(storedCouponEntries);
      couponEntries.forEach((entry: any) => {
        dispatch(addCoupon(entry));
      });
    }
  }, [dispatch]);

  return (
    <AppContainer>
      <TopNavigation />
      <Sidebar />
      <MainContentContainer>
        <Routes>
          <Route path="/" element={<HomeView />} />
          <Route path="/board" element={<BoardView />} />
          <Route path="/user" element={<UserView />} />
          <Route path="/settings" element={<SettingsView />} />
          <Route path="/contact" element={<ContactView />} />
          <Route path="/about" element={<AboutView />} />
          <Route path="/support" element={<SupportView />} />
          <Route path="/sign-in/" element={<SignIn routing="path" path="/sign-in" />} />
          <Route path="/sign-up/" element={<SignUp routing="path" path="/sign-up" />} />
          <Route path="/websocket-test" element={<WebSocketTest />} /> {/* Add this route */}
          <Route path="/live-score" element={<LiveScore />} />
        </Routes>
      </MainContentContainer>
      {location.pathname === '/' && couponEntries.length > 0 && (
        <FloatingActionBar entryCount={couponEntries.length} />
      )}
    </AppContainer>
  );
};

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ClerkProvider publishableKey={clerkPublishableKey}>
        <Router>
          <AppContent />
        </Router>
      </ClerkProvider>
    </Provider>
  );
};

export default App;
