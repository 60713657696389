//FinalMatch.css.ts
import styled from 'styled-components';

export const StyledFinalMatch = styled.div`
   border-radius: 4px;
  padding: 1px 14px 10px;
  background-color: #1B1B1F;
  text-align: left;
  color: #B8B8B9;
  width: 100%;
  box-sizing: border-box;

  h3 {
    color: white; /* Live text color */
    margin-bottom: 4px;
    font-size: 1.13rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date {
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  .button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .team-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .team-info {
    display: flex;
    align-items: center;
    color: #B8B8B9;
    font-weight: 500;
    flex-grow: 1;
    gap: 10px;
    width: 100%;
  }

  .team-image-container {
    display: flex;
    align-items: center;
    min-width: 120px; /* Ensure minimum width for shortname */
  }

  .team-shortname {
    font-weight: bold;
    color: #FFFFFF;
    margin-left: 10px; /* Ensure some space between image and text */
  }

  img {
    width: 35px;
    height: 35px;
    object-fit: contain;

    @media (min-width: 575px) {
      width: 45px;
      height: 45px;
    }
  }

  .buttons {
    display: flex;
    gap: 10px;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    justify-content: space-around; /* Ensure even distribution of space */
  }

  .hint-button-pair {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    position: relative;
  }

  .hint {
    text-align: center;
    font-weight: 600;
    font-size: 0.8rem;
    margin-bottom: 5px;
  }

  .score {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }

  .pick-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0BB659; /* Green background */
  color: white; /* Text color */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px; /* Adjust as needed */
}

  .course {
    font-weight: 600; /* Increase text weight */
    padding: 6px 8px; /* Added padding */
    width: 100%; /* Ensure all buttons take the same width */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowed text */
    text-overflow: ellipsis; /* Show ellipsis for overflowed text */
    color: #FFFFFF;
    border: 2px solid transparent;
    background-color: #1f1f24;
    border-radius: 4px;
    text-align: center; /* Center the text */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* To position the dots */

    &.correct {
      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #0BB659; /* Green dot for correct */
        border-radius: 50%;
        position: absolute;
        bottom: 4px;
        right: 4px;
      }
    }

    &.push {
      &::after {
        content: '';
        width: 12px;
        height: 12px;
        background-color: #808080; /* Gray dot for push */
        border-radius: 50%;
        position: absolute;
        bottom: 4px;
        right: 4px;
      }
    }

    span {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .your-selection {
  text-align: right; 
  color: #f5f5f5;
  font-size: 0.9rem; /* Adjust the font size as needed */
}

.pick-circle {
  width: 70px;
  height: 23px;
  background: linear-gradient(135deg, #1B1B1F, #28282e); /* Gradient background */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 1000;
  border: 1px solid #3a3a43;
  border-radius: 15px; /* Rounded corners */
  opacity: 0.8;
  margin-left: 31px;
  margin-top: 10px;
}


.header-row {
    display: flex;
    align-items: center;
    
    margin-bottom: 4px;
  }

   /* Dialog Box Styles */
  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .dialog-content {
    background: #1b1b1f;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    color: white;
    position: relative; /* Make positioning for close button easier */
     margin: 0 10px; /* Default margin for non-mobile screens */

    @media (max-width: 575px) {
    padding: 8px; /* Adjust padding for mobile screens */
    margin: 0 15px; /* Add extra margin on mobile screens to create padding around the dialog box */
  }
  }

  .dialog-content h4 {
    margin-bottom: 10px;
    color: white;
    text-align: center;
  }

 .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #000000; /* Ensure black background */
    border: none;
    color: white;
    font-size: 18px; /* Smaller font size */
    width: 24px; /* Adjust size */
    height: 24px; /* Adjust size */
    border-radius: 50%; /* Make it circular */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
        background-color: #333333; /* Slightly lighter on hover */
    }

    &:focus {
        outline: none; /* Remove the focus outline for a cleaner look */
    }
}

  

  .divider {
    border: 0;
    height: 1px;
    background: #333;
    margin: 15px 0;
  }

  .dialog-content ul {
    list-style-type: none;
    padding: 0;
  }

  .dialog-content li {
    margin-bottom: 5px;
  }

  .dialog-content button {
    margin-top: 10px;
    padding: 8px 12px;
    background: #007bff;
    color: pink;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #0056b3;
    }
  }

  .dialog-content .picks-container {
  border-radius: 4px;
  max-height: 300px; /* Set a fixed height */
  
  overflow-y: auto; /* Enable vertical scrolling */
  margin-top: 10px; /* Add some spacing above the picks */
  padding: 10px; /* Add padding to prevent content from hiding behind the scrollbar */
}

`;
