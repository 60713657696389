
import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, clearCoupon, removeCoupon } from './store';
import {
  Container,
  Title,
  EntriesContainer,
  EntryBox,
  RemoveButton,
  ButtonContainer,
  ClearButton,
  TrashIcon,
} from './MobileCoupon.css';
import { useUser } from '@clerk/clerk-react';
import axios from 'axios';

interface MobileCouponDisplayProps {
  setSuccessMessage: (value: boolean) => void;
}

const MAX_COUPON_ENTRIES = 50; // Set the maximum number of coupon entries

const MobileCouponDisplay: React.FC<MobileCouponDisplayProps> = ({ setSuccessMessage }) => {
  const couponEntries = useSelector((state: RootState) => state.coupon.coupon);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSignedIn, user } = useUser();
  const entriesContainerRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    localStorage.setItem('couponEntries', JSON.stringify(couponEntries));
  }, [couponEntries]);

  useEffect(() => {
    if (entriesContainerRef.current) {
      entriesContainerRef.current.scrollTop = entriesContainerRef.current.scrollHeight;
    }
  }, [couponEntries]);

  const handleSubmit = async () => {
    if (!isSignedIn) {
      localStorage.setItem('couponEntries', JSON.stringify(couponEntries));
      window.location.href = '/sign-in';
      return;
    }
  
    if (couponEntries.length === 0) {
      alert('Cannot submit an empty coupon');
      return;
    }
  
    try {
      const userId = user?.id;
  
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/picks`, {
        userId,
        picks: couponEntries,
      });
  
      if (response.status !== 200) {
        throw new Error('Failed to submit picks');
      }

      setSuccessMessage(true);  // Trigger the success message display
  
      
      localStorage.removeItem('couponEntries'); // Remove the coupon entries from local storage
  
      
    } catch (error) {
      console.error('Error submitting picks:', error);
      alert(`Failed to submit picks: ${(error as Error).message}`);
    }
  };
  

  const handleRemove = (id: string) => {
    dispatch(removeCoupon(id));
  };

  const renderEntryValue = (entry: any) => {
    if (entry.type === 'winner') {
      return entry.value;
    }
    if (entry.type === 'spread') {
      return entry.id.includes('spreadHome')
        ? `${entry.teamHome} ${entry.value}`
        : `${entry.teamAway} ${entry.value}`;
    }
    return entry.value; // For 'total' type
  };

  return (
    <Container>
      <Title>Your Coupon {couponEntries.length > 0 ? `(${couponEntries.length})` : ''}</Title>
      {couponEntries.length === 0 ? (
        <p>Your coupon is empty</p>
      ) : (
        <EntriesContainer ref={entriesContainerRef}>
          {couponEntries.map((entry, index) => (
            <EntryBox key={index}>
              <div>{entry.teamAway} - {entry.teamHome}</div>
              <div>{entry.type}</div>
              <div style={{ color: 'green' }}>{renderEntryValue(entry)}</div>
              <RemoveButton onClick={() => handleRemove(entry.id)}>X</RemoveButton>
            </EntryBox>
          ))}
        </EntriesContainer>
      )}
      {couponEntries.length > 0 && (
        <ButtonContainer>
          <ClearButton onClick={() => dispatch(clearCoupon())}>
            <TrashIcon />
            Clear Coupon
          </ClearButton>
          <button
            onClick={handleSubmit}
            disabled={couponEntries.length >= MAX_COUPON_ENTRIES}
          >
            {isSignedIn ? 'Submit Coupon' : 'Log in to save'}
          </button>
          {couponEntries.length >= MAX_COUPON_ENTRIES && (
            <p style={{ color: 'red' }}>You have reached the maximum of {MAX_COUPON_ENTRIES} entries.</p>
          )}
        </ButtonContainer>
      )}
    </Container>
  );
};

export default MobileCouponDisplay;
