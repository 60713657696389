import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CouponEntry {
  eventId: string;
  column: string;
  id: string;
  value: string;
  teamAway: string;
  teamHome: string;
  type: string;
}

interface CouponState {
  coupon: CouponEntry[];
  activeButtons: { [key: string]: { [key: string]: string } };
}

const initialState: CouponState = {
  coupon: [],
  activeButtons: {},
};

const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    addCoupon: (state, action: PayloadAction<CouponEntry>) => {
      const { eventId, column, id, value, teamAway, teamHome, type } = action.payload;
      // Replace selection for the same column in the same event
      state.coupon = state.coupon.filter(entry => !(entry.eventId === eventId && entry.column === column));
      state.coupon.push(action.payload);

      if (!state.activeButtons[eventId]) {
        state.activeButtons[eventId] = {};
      }
      state.activeButtons[eventId][column] = id;
    },
    removeCoupon: (state, action: PayloadAction<string>) => {
      state.coupon = state.coupon.filter(entry => entry.id !== action.payload);
      // Also remove from activeButtons
      Object.keys(state.activeButtons).forEach(eventId => {
        Object.keys(state.activeButtons[eventId]).forEach(column => {
          if (state.activeButtons[eventId][column] === action.payload) {
            delete state.activeButtons[eventId][column];
          }
        });
      });
    },
    clearCoupon: (state) => {
      state.coupon = [];
      state.activeButtons = {};
    },
    setCoupon: (state, action: PayloadAction<CouponEntry[]>) => {
      state.coupon = action.payload;
      state.activeButtons = {};
      action.payload.forEach(entry => {
        if (!state.activeButtons[entry.eventId]) {
          state.activeButtons[entry.eventId] = {};
        }
        state.activeButtons[entry.eventId][entry.column] = entry.id;
      });
    },
  },
});

export const { addCoupon, removeCoupon, clearCoupon, setCoupon } = couponSlice.actions;

const store = configureStore({
  reducer: {
    coupon: couponSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
