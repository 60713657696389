import React, { useEffect, useState } from 'react';
import { 
  ContentWrapper, 
  MainContent, 
  MatchesContainer, 
  CouponWrapper, 
  MatchesSection, 
  DropdownWrapper, 
  Dropdown 
} from './App.css';
import ImportantMatch from './ImportantMatch';
import LiveMatch from './LiveMatch';
import FinalMatch from './FinalMatch';
import CouponContainerComponent from './CouponContainer';
import Carousel from './Carousel';
import SkeletonMatch from './SkeletonMatch';
import { useUser } from '@clerk/clerk-react'; 

const HomeView: React.FC = () => {
  const { user } = useUser(); // Use Clerk's hook to get the current user
  const userId = user?.id; // Safely access the user ID
  const API_URL = process.env.REACT_APP_API_URL;

  // **1. Define calculateCurrentWeek before using it**
  const calculateCurrentWeek = () => {
    const now = new Date();
    const weeks = [
      { week: -3, end: new Date('2024-08-08T23:59:59') }, // Preseason Week 3
      { week: 1, end: new Date('2024-09-09T23:59:59') }, // Regular Season Week 1
      { week: 2, end: new Date('2024-09-16T23:59:59') },
      { week: 3, end: new Date('2024-09-23T23:59:59') },
      { week: 4, end: new Date('2024-09-30T23:59:59') },
      { week: 5, end: new Date('2024-10-07T23:59:59') },
      { week: 6, end: new Date('2024-10-14T23:59:59') },
      { week: 7, end: new Date('2024-10-21T23:59:59') },
      { week: 8, end: new Date('2024-10-28T23:59:59') },
      { week: 9, end: new Date('2024-11-04T23:59:59') },
      { week: 10, end: new Date('2024-11-11T23:59:59') },
      { week: 11, end: new Date('2024-11-18T23:59:59') },
      { week: 12, end: new Date('2024-11-25T23:59:59') },
      { week: 13, end: new Date('2024-12-02T23:59:59') },
      { week: 14, end: new Date('2024-12-09T23:59:59') },
      { week: 15, end: new Date('2024-12-16T23:59:59') },
      { week: 16, end: new Date('2024-12-23T23:59:59') },
      { week: 17, end: new Date('2024-12-30T23:59:59') },
      { week: 18, end: new Date('2025-01-05T23:59:59') },
    ];

    const currentWeek = weeks.find(week => now <= week.end);
    return currentWeek ? currentWeek.week : 18;
  };

  // **2. Initialize selectedWeek with a default value and update it in useEffect**
  const [selectedWeek, setSelectedWeek] = useState<number>(1); // Initialize with a default

  const [games, setGames] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Retrieve the selected week from localStorage if available
    const storedWeek = localStorage.getItem('selectedWeek');
    if (storedWeek !== null) {
      setSelectedWeek(Number(storedWeek));
    } else {
      // Otherwise, use the current week
      const currentWeek = calculateCurrentWeek();
      setSelectedWeek(currentWeek);
      localStorage.setItem('selectedWeek', currentWeek.toString()); // Optional: Store the calculated week
    }
  }, [calculateCurrentWeek]); // No need to include calculateCurrentWeek in dependencies as it's stable

  useEffect(() => {
    const fetchGames = async () => {
      setLoading(true);
      setError(null);
      try {
        // Adjust the API endpoint based on whether the user is logged in
        const url = userId 
          ? `${API_URL}/games/week/${selectedWeek}/${userId}`
          : `${API_URL}/games/week/${selectedWeek}`;
    
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Fetched data:', data);
  
        const formattedData = data.map((game: any) => ({
          ...game,
          overUnderTotal: game.overUnderTotal ? parseFloat(game.overUnderTotal.$numberDecimal) : null,
        }));
  
        setGames(formattedData);
      } catch (error) {
        setError('Failed to fetch data. Reload the page. Or try again later.');
        console.error('Fetch error:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchGames();
  }, [selectedWeek, userId, API_URL]); // Added API_URL to dependencies

  const handleWeekChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedWeek = Number(event.target.value);
    setSelectedWeek(newSelectedWeek);
    // Store the selected week in localStorage
    localStorage.setItem('selectedWeek', newSelectedWeek.toString());
  };

  const images = [
    "https://firebasestorage.googleapis.com/v0/b/mapping-367400.appspot.com/o/images%2Fnfl%20logos%2Fpromos%2Fsavepromo.jpg?alt=media&token=37e1b407-c66c-47ad-86ac-dac862ed8c44",
    "https://firebasestorage.googleapis.com/v0/b/mapping-367400.appspot.com/o/images%2Fnfl%20logos%2Fpromos%2Flatestpromo.jpg?alt=media&token=608a1229-a601-4deb-8dc4-700c8183d324",
    "https://firebasestorage.googleapis.com/v0/b/mapping-367400.appspot.com/o/images%2Fnfl%20logos%2Fpromos%2Flivepromo.jpg?alt=media&token=7754d182-38f5-4710-a0ba-ae46fd4c0807",
    "https://firebasestorage.googleapis.com/v0/b/mapping-367400.appspot.com/o/images%2Fnfl%20logos%2Fpromos%2Fdatapromo.jpg?alt=media&token=ab137c70-32e6-4ed8-b2ac-643f54eb41ee",
    "https://firebasestorage.googleapis.com/v0/b/mapping-367400.appspot.com/o/images%2Fnfl%20logos%2Fpromos%2Feverypromo.jpg?alt=media&token=c69f673b-545a-4cc4-a370-f93707491dc0",

  ];


  const sortedGames = [...games].sort((a, b) => {
    // Live matches first
    if (a.started && !a.ended && (!b.started || b.ended)) return -1;
    if ((!a.started || a.ended) && b.started && !b.ended) return 1;

    // Important matches second
    if (!a.started && !b.started) return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
    if (!a.started && b.ended) return -1;
    if (a.ended && !b.started) return 1;

    // Final matches last
    if (a.ended && b.ended) return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();

    return 0;
  });

  return (
    <MainContent>
      <ContentWrapper>
        <MatchesSection>
          <Carousel images={images} />
          {loading ? (
            <MatchesContainer>
              <SkeletonMatch />
              <SkeletonMatch />
              <SkeletonMatch />
              <SkeletonMatch />
              <SkeletonMatch />
              <SkeletonMatch />
            </MatchesContainer>
          ) : error ? (
            <div>{error}</div>
          ) : (
            <>
              <DropdownWrapper>
                <Dropdown onChange={handleWeekChange} value={selectedWeek}>
                  {/* Add Preseason week */}
                  <option value={-3}>Preseason Week 3</option>
                  {/* Add Regular Season weeks */}
                  {Array.from({ length: 18 }, (_, i) => (
                    <option key={i + 1} value={i + 1}>
                      Week {i + 1}
                    </option>
                  ))}
                </Dropdown>
              </DropdownWrapper>
              <MatchesContainer>
                {sortedGames.map((game) =>
                  game.started ? (
                    game.ended ? (
                      <FinalMatch
                        key={game._id}
                        eventId={game._id}
                        league={game.sport}
                        teamHome={{
                          teamName: game.teamHome.teamName,
                          shortName: game.teamHome.shortName,
                          image: game.teamHome.image,
                          spreadHome: game.spreadHome,
                          spreadAway: null,
                          overUnderTotal: game.overUnderTotal,
                        }}
                        teamAway={{
                          teamName: game.teamAway.teamName,
                          shortName: game.teamAway.shortName,
                          image: game.teamAway.image,
                          spreadHome: null,
                          spreadAway: game.spreadAway,
                          overUnderTotal: game.overUnderTotal,
                        }}
                        week={game.weekNum}
                        homeScore={game.teamHomeScore}
                        awayScore={game.teamAwayScore}
                        userPicks={userId ? game.picks : []}
                      />
                    ) : (
                      <LiveMatch
                        key={game._id}
                        eventId={game._id}
                        league={game.sport}
                        teamHome={{
                          teamName: game.teamHome.teamName,
                          shortName: game.teamHome.shortName,
                          image: game.teamHome.image,
                          spreadHome: game.spreadHome,
                          spreadAway: null,
                          overUnderTotal: game.overUnderTotal,
                        }}
                        teamAway={{
                          teamName: game.teamAway.teamName,
                          shortName: game.teamAway.shortName,
                          image: game.teamAway.image,
                          spreadHome: null,
                          spreadAway: game.spreadAway,
                          overUnderTotal: game.overUnderTotal,
                        }}
                        userPicks={userId ? game.picks : []}
                      />
                    )
                  ) : (
                    <ImportantMatch
                      key={game._id}
                      eventId={game._id}
                      league={game.sport}
                      teamHome={{
                        teamName: game.teamHome.teamName,
                        shortName: game.teamHome.shortName,
                        image: game.teamHome.image,
                        spreadHome: game.spreadHome,
                        spreadAway: null,
                        overUnderTotal: game.overUnderTotal,
                      }}
                      teamAway={{
                        teamName: game.teamAway.teamName,
                        shortName: game.teamAway.shortName,
                        image: game.teamAway.image,
                        spreadHome: null,
                        spreadAway: game.spreadAway,
                        overUnderTotal: game.overUnderTotal,
                      }}
                      date={game.startTime}
                      userPicks={userId ? game.picks : []}
                    />
                  )
                )}
              </MatchesContainer>
            </>
          )}
        </MatchesSection>
        <CouponWrapper>
          <CouponContainerComponent />
        </CouponWrapper>
      </ContentWrapper>
    </MainContent>
  );
};

export default HomeView;
