import React, { useEffect, useState } from 'react';

interface GameData {
  AwayTeam: string;
  HomeTeam: string;
  AwayTeamRuns: number;
  HomeTeamRuns: number;
  AwayTeamHits: number;
  HomeTeamHits: number;
  Inning: number;
  InningHalf: string;
  Status: string;
}

const LiveScore: React.FC = () => {
  const [gameData, setGameData] = useState<GameData | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const ws = new WebSocket('ws://localhost:5000'); // Replace with your server URL

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
    };

    ws.onmessage = (event) => {
      const data: GameData = JSON.parse(event.data);
      console.log('Received data from WebSocket:', data);
      setGameData(data);
    };

    ws.onerror = (err) => {
      console.error('WebSocket error:', err);
      setError('WebSocket error');
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      ws.close();
    };
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!gameData) {
    return <div>Waiting for live data...</div>;
  }

  return (
    <div>
      <h2>Live Score</h2>
      <p>{`Inning: ${gameData.Inning} (${gameData.InningHalf})`}</p>
      <p>{`${gameData.AwayTeam} - Runs: ${gameData.AwayTeamRuns}, Hits: ${gameData.AwayTeamHits}`}</p>
      <p>{`${gameData.HomeTeam} - Runs: ${gameData.HomeTeamRuns}, Hits: ${gameData.HomeTeamHits}`}</p>
      <p>{`Status: ${gameData.Status}`}</p>
    </div>
  );
};

export default LiveScore;
