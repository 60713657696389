import React, { useState, useEffect } from 'react';
import { FloatingBarContainer, ExpandedCouponContainer, EntryCountBox, CloseButton, SuccessContainer, SuccessCircle, SuccessText, ContinueButton } from './FloatingActionBar.css';
import { FaClipboardList } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import CustomCouponDisplay from './MobileCoupon';

interface FloatingActionBarProps {
  entryCount: number;
}

const FloatingActionBar: React.FC<FloatingActionBarProps> = ({ entryCount }) => {
  const [showCoupon, setShowCoupon] = useState(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);

  useEffect(() => {
    if (showCoupon) {
      document.body.style.overflow = 'hidden'; // Prevent body from scrolling
    } else {
      document.body.style.overflow = ''; // Reset overflow
    }
    return () => {
      document.body.style.overflow = ''; // Cleanup on component unmount
    };
  }, [showCoupon]);

  const handleContinue = () => {
    window.location.reload(); // Refresh the page
  };

  return (
    <>
      <FloatingBarContainer onClick={() => setShowCoupon(!showCoupon)}>
        <FaClipboardList size={24} />
        <span>View Coupon</span>
        <EntryCountBox>{entryCount}</EntryCountBox>
      </FloatingBarContainer>
      {showCoupon && (
        <ExpandedCouponContainer>
          {/* Conditionally render the CloseButton */}
          {!successMessage && (
            <CloseButton onClick={() => setShowCoupon(false)}>
              <AiOutlineClose size={14} />
            </CloseButton>
          )}
          {successMessage ? (
            <SuccessContainer>
              <SuccessCircle>✔️</SuccessCircle>
              <SuccessText>Picks submitted successfully</SuccessText>
              <ContinueButton onClick={handleContinue}>Continue</ContinueButton>
            </SuccessContainer>
          ) : (
            <CustomCouponDisplay setSuccessMessage={setSuccessMessage} />
          )}
        </ExpandedCouponContainer>
      )}
    </>
  );
};

export default FloatingActionBar;
