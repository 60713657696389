// UserView.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useUser, SignIn } from '@clerk/clerk-react'; 
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import SkeletonPick from './SkeletonPick';
import {
  MainContent,
  PicksContainer,
  PickItem,
  TeamLogosContainer,
  TeamLogos,
  TeamAcronyms,
  PickDetails,
  PickStatus,
  WeekText,
  UserProfileContainer,
  UserProfile,
  UserName,  // Keep UserName for displaying the username under the profile picture
  ProfileColumn, // New import for the profile picture and username column
  StatsColumn, // New import for the stats columns
  StatValue,  // New import for the values of the stats
  StatLabel,  // New import for the labels of the stats
  FilterButtonContainer,
  FilterButton,
} from './UserView.css';


interface Team {
  _id: string;
  fullName: string;
  cityName: string;
  teamName: string;
  shortName: string;
  image: string;
}

interface Game {
  _id: string;
  startTime: string;
  ended: boolean;
  started: boolean;
  weekNum: number;
  teamHomeScore: number;
  teamAwayScore: number;
  oddsHomeWin: number;
  oddsAwayWin: number;
  spreadHome: number;
  spreadAway: number;
  overUnderTotal: { $numberDecimal: string };
  teamHome: Team;
  teamAway: Team;
  sport: string;
}

interface Pick {
  eventId: Game;
  column: string;
  id: string;
  value: string;
  type: string;
  teamAway: string;
  teamHome: string;
  isWin: string | null;
  _id: string;
}

interface UserPick {
  _id: string;
  userId: string;
  picks: Pick[];
  timestamp: string;
}
const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  margin-bottom: 150px;
`;

const SignInText = styled.div`
  font-weight: bold;
  margin-bottom: 20px; // Adjust spacing between text and sign-in box
  font-size: 1.2em; // Make the text slightly larger if needed
`;

const UserView: React.FC = () => {
  const { isSignedIn, user } = useUser();
  const navigate = useNavigate(); // Initialize useNavigate
  const [userPicks, setUserPicks] = useState<UserPick[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalWins, setTotalWins] = useState<number>(0);
  const [totalLosses, setTotalLosses] = useState<number>(0);
  const [totalPicks, setTotalPicks] = useState<number>(0);
  const [filter, setFilter] = useState<'upcoming' | 'results'>('upcoming');
  
  const winPercentage = totalPicks > 0 ? ((totalWins / totalPicks) * 100).toFixed(2) : '0';

  useEffect(() => {
    if (isSignedIn && user) {
      axios.get(`${process.env.REACT_APP_API_URL}/user/${user.id}/picks`)
        .then(response => {
          const picks = response.data;
          setUserPicks(picks);
          calculateStats(picks);
          setLoading(false);
        })
        .catch(error => {
          setError('Error displaying picks');
          setLoading(false);
          console.error('Error fetching picks:', error);
        });
    }
  }, [isSignedIn, user]);

  const calculateStats = (picks: UserPick[]) => {
    let wins = 0;
    let losses = 0;
    let total = 0;

    picks.forEach(userPick => {
      userPick.picks.forEach(pick => {
        total += 1;
        if (pick.isWin === 'win') {
          wins += 1;
        } else if (pick.isWin === 'loss') {
          losses += 1;
        }
      });
    });

    setTotalWins(wins);
    setTotalLosses(losses);
    setTotalPicks(total);
  };

  const getPickDisplay = (pick: Pick) => {
    if (pick.type === 'spread') {
      const isHomePick = pick.id.includes('spreadHome');
      const teamName = isHomePick ? pick.teamHome : pick.teamAway;
      return `${teamName} ${pick.value}`;
    }
    return pick.value;
  };

  const mapPickStatus = (status: string | null): 'win' | 'lose' | 'neutral' => {
    switch (status) {
      case 'win':
        return 'win';
      case 'loss':
        return 'lose';
      case 'draw':
        return 'neutral';
      default:
        return 'neutral';
    }
  };

  const allPicks = userPicks.flatMap(userPick => userPick.picks);
  const filteredPicks = allPicks.filter(pick => 
    filter === 'upcoming' ? pick.isWin === null : pick.isWin !== null
  );
  const sortedPicks = filteredPicks.slice().reverse();


  return (
    <MainContent>
      {!isSignedIn ? (
         <CenteredContainer>
         <SignInText>Please sign in to view your picks.</SignInText>
         <SignIn /> {/* Clerk sign-in box */}
       </CenteredContainer>
      ) : (
        <>
          <UserProfileContainer>
            <ProfileColumn>
              <UserProfile src={user?.imageUrl} alt="user profile" />
              <UserName>{user?.username}</UserName>
            </ProfileColumn>
            <StatsColumn>
              <StatValue>{totalPicks}</StatValue>
              <StatLabel>Picks</StatLabel>
            </StatsColumn>
            <StatsColumn>
              <StatValue>{totalWins} W - {totalLosses} L</StatValue>
              <StatLabel>Record</StatLabel>
            </StatsColumn>
            <StatsColumn>
              <StatValue winPct={parseFloat(winPercentage)}>{winPercentage}%</StatValue>
              <StatLabel>Win Pct.</StatLabel>
            </StatsColumn>
          </UserProfileContainer>
          <PicksContainer>
          <FilterButtonContainer>
            <FilterButton
              isSelected={filter === 'upcoming'}
              onClick={() => setFilter('upcoming')}
            >
              Upcoming
            </FilterButton>
            <FilterButton
              isSelected={filter === 'results'}
              onClick={() => setFilter('results')}
            >
              Results
            </FilterButton>
          </FilterButtonContainer>
          {loading ? (
            Array.from({ length: 4 }).map((_, index) => <SkeletonPick key={index} />)
          ) : error ? (
            <div style={{ color: 'red' }}>{error}</div>
          ) : sortedPicks.length === 0 ? (
            <div>{filter === 'upcoming' ? 'No upcoming picks' : 'No results yet'}</div>
          ) : (
            sortedPicks.map((pick, index) => (
              <PickItem key={pick._id} index={index}>
                <TeamLogosContainer>
                  <TeamLogos>
                    <img src={pick.eventId.teamAway.image} alt={pick.eventId.teamAway.shortName} />
                    <span>-</span>
                    <img src={pick.eventId.teamHome.image} alt={pick.eventId.teamHome.shortName} />
                  </TeamLogos>
                  <TeamAcronyms>
                    <div>{pick.eventId.teamAway.shortName}</div>
                    <div>{pick.eventId.teamHome.shortName}</div>
                  </TeamAcronyms>
                </TeamLogosContainer>
                <PickDetails>
                  <div>{getPickDisplay(pick)}</div>
                  <PickStatus status={mapPickStatus(pick.isWin)} />
                </PickDetails>
                <WeekText>Week {pick.eventId.weekNum}</WeekText>
              </PickItem>
            ))
          )}
        </PicksContainer>
          
          

        </>
      )}
    </MainContent>
  );
};

export default UserView;





