import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, clearCoupon, removeCoupon, addCoupon } from './store'; // Assuming you have an addCoupon action
import {
  CouponContainer,
  CouponEntriesContainer,
  CouponEntryBox,
  RemoveButton,
  ButtonContainer,
  ClearButtonContainer,
  TrashIcon,
  EmptyMessageContainer,
  ClipboardIcon,
  EmptyText,
} from './CouponContainer.css';
import { useUser } from '@clerk/clerk-react';
import axios from 'axios';
import SuccessModal from './SuccessModal';

const CouponContainerComponent: React.FC = () => {
  const couponEntries = useSelector((state: RootState) => state.coupon.coupon);
  const dispatch = useDispatch();
  const { isSignedIn, user } = useUser();
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const containerRef = useRef<HTMLUListElement | null>(null); // Reference for the container

  useEffect(() => {
    // Check for saved coupon entries in local storage on component mount
    const savedEntries = localStorage.getItem('couponEntries');
    if (savedEntries) {
      const parsedEntries = JSON.parse(savedEntries);
      parsedEntries.forEach((entry: any) => {
        dispatch(addCoupon(entry)); // Dispatch an action to restore entries
      });
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem('couponEntries', JSON.stringify(couponEntries));

    // Automatically scroll the CouponEntriesContainer to the latest entry
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight; // Scroll to bottom
    }
  }, [couponEntries]);

  const handleSubmit = async () => {
    if (!isSignedIn) {
      localStorage.setItem('couponEntries', JSON.stringify(couponEntries));
      window.location.href = '/sign-in';
      return;
    }

    if (couponEntries.length === 0) {
      alert('Cannot submit an empty coupon');
      return;
    }

    try {
      const userId = user?.id;

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/picks`, {
        userId,
        picks: couponEntries,
      });

      if (response.status !== 200) {
        throw new Error('Failed to submit picks');
      }

      setShowSuccessModal(true); // Show the success modal on successful submission
    } catch (error) {
      console.error('Error submitting picks:', error);
      alert(`Failed to submit picks: ${(error as Error).message}`);
    }
  };

  const handleRemove = (id: string) => {
    dispatch(removeCoupon(id));
  };

  const renderEntryValue = (entry: any) => {
    if (entry.type === 'winner') {
      return entry.value;
    }
    if (entry.type === 'spread') {
      return entry.id.includes('spreadHome')
        ? `${entry.teamHome} ${entry.value}`
        : `${entry.teamAway} ${entry.value}`;
    }
    return entry.value; // For 'total' type
  };

  const closeSuccessModal = () => {
    dispatch(clearCoupon()); // Clear coupon entries from Redux state
    localStorage.removeItem('couponEntries'); // Remove the coupon entries from local storage
    setShowSuccessModal(false);
    window.location.reload(); // Refresh the page
  };

  return (
    <CouponContainer>
      {showSuccessModal && <SuccessModal onClose={closeSuccessModal} />}
      <h2>Your Coupon {couponEntries.length > 0 ? `(${couponEntries.length})` : ''}</h2>
      {couponEntries.length === 0 ? (
        <EmptyMessageContainer>
          <ClipboardIcon />
          <EmptyText>Coupon is empty</EmptyText>
        </EmptyMessageContainer>
      ) : (
        <CouponEntriesContainer ref={containerRef}>
          {couponEntries.map((entry, index) => (
            <CouponEntryBox key={index}>
              <div>{entry.teamAway} - {entry.teamHome}</div>
              <div>{entry.type}</div>
              <div style={{ color: 'green' }}>{renderEntryValue(entry)}</div>
              <RemoveButton onClick={() => handleRemove(entry.id)}>X</RemoveButton>
            </CouponEntryBox>
          ))}
        </CouponEntriesContainer>
      )}
      {couponEntries.length > 0 && (
        <ButtonContainer>
          <ClearButtonContainer onClick={() => dispatch(clearCoupon())}>
            <TrashIcon />
            Clear Coupon
          </ClearButtonContainer>
          <button onClick={handleSubmit}>{isSignedIn ? 'Save Picks' : 'Log in to save'}</button>
        </ButtonContainer>
      )}
    </CouponContainer>
  );
};

export default CouponContainerComponent;
