import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaCog, FaUndoAlt, FaSignOutAlt } from 'react-icons/fa';
import { useClerk, useUser } from '@clerk/clerk-react';
import axios from 'axios';

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #1B1B1F;
  color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  @media (max-width: 600px) {
    padding: 20px 15px; /* Increased padding for smaller screens */
    box-sizing: border-box;
  }
`;

const Option = styled.div`
  padding: 15px;
  margin: 10px 0;
  width: calc(100% - 40px); /* Add padding inside the option */
  background-color: #28282E;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #686877;
  }

  svg {
    margin-right: 10px;
    font-size: 1.2em;
  }

  @media (max-width: 600px) {
    width: calc(100% - 30px); /* Adjust padding for smaller screens */
  }
`;

const SignOutOption = styled(Option)`
  color: #ff3b3b;

  &:hover {
    background-color: #9d0000;
    color: white;
  }
`;

const BackButton = styled.button`
  align-self: flex-start;
  padding: 10px 20px;
  margin-bottom: 20px;
  background-color: #4c4c58;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #9a9aa7;
  }
`;

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dialog = styled.div`
  background: #1B1B1F;
  padding: 30px; /* Add padding inside the dialog */
  border-radius: 10px;
  text-align: center;
  color: white;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 600px) {
    padding: 20px; /* Adjust padding for smaller screens */
    width: 95%; /* Make the dialog slightly narrower on small screens */
  }
`;

const DialogText = styled.p`
  margin-bottom: 20px;
  line-height: 1.5;
`;

const DialogButton = styled.button`
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #4c4c58;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #686877;
  }
`;

const DialogLink = styled.span`
  color: #ff3b3b;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 10px;

  &:hover {
    color: #ff0000;
  }
`;

const MessageContainer = styled.div`
  margin: 20px 0;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  max-width: 600px;
`;

const SuccessMessage = styled(MessageContainer)`
  background-color: #4CAF50;
  color: white;
`;

const ErrorMessage = styled(MessageContainer)`
  background-color: #f44336;
  color: white;
`;

const LoadingMessage = styled(MessageContainer)`
  background-color: #333;
  color: white;
`;

const SettingsView: React.FC = () => {
  const navigate = useNavigate();
  const { signOut, openUserProfile } = useClerk();
  const { user } = useUser();
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    if (success || error) {
      const timer = setTimeout(() => {
        setSuccess(null);
        setError(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [success, error]);

  const handleManageAccount = () => {
    openUserProfile();
  };

  const handleSignOut = () => {
    signOut();
    navigate('/sign-in');
  };

  const handleResetPicks = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/user/${user?.id}/picks`);
      setLoading(false);
      setShowDialog(false);
      setSuccess('Picks reset successfully');
    } catch (error) {
      setLoading(false);
      console.error('Error resetting picks:', error);
      setError('Failed to reset picks');
    }
  };

  return (
    <SettingsContainer>
      <BackButton onClick={() => navigate('/user')}>Back</BackButton>
      <h1>Settings</h1>
      <Option onClick={handleManageAccount}>
        <FaCog />
        Manage Account
      </Option>
      <Option onClick={() => setShowDialog(true)}>
        <FaUndoAlt />
        Reset Picks
      </Option>
      <SignOutOption onClick={handleSignOut}>
        <FaSignOutAlt />
        Sign out
      </SignOutOption>

      {showDialog && (
        <DialogOverlay>
          <Dialog>
            <DialogText>This action will delete all of the picks you have made. Are you sure you want to continue?</DialogText>
            <div>
              <DialogButton onClick={() => setShowDialog(false)}>Cancel</DialogButton>
              <DialogLink onClick={handleResetPicks}>Reset picks</DialogLink>
            </div>
          </Dialog>
        </DialogOverlay>
      )}

      {loading && <LoadingMessage>Processing...</LoadingMessage>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {success && <SuccessMessage>{success}</SuccessMessage>}
    </SettingsContainer>
  );
};

export default SettingsView;
