// SkeletonMatch.tsx
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 14px 10px;
  background-color: #1b1b1f;
  border-radius: 4px;
  margin-bottom: 20px;
`;

const SkeletonElement = styled.div`
  background: linear-gradient(90deg, #1b1b1f 25%, #2d2d34 50%, #1b1b1f 75%);
  background-size: 1000px 100%;
  animation: ${shimmer} 2s infinite;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 100%;
  height: 20px;
`;

const SkeletonImage = styled(SkeletonElement)`
  height: 45px;
  width: 45px;
`;

const SkeletonMatch = () => {
  return (
    <SkeletonContainer>
      <SkeletonElement style={{ height: '20px', width: '50%' }} />
      <SkeletonElement style={{ height: '15px', width: '70%' }} />
      <SkeletonElement style={{ height: '30px', width: '100%' }} />
      <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
        <SkeletonImage />
        <SkeletonImage />
      </div>
    </SkeletonContainer>
  );
};

export default SkeletonMatch;