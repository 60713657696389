import styled from 'styled-components';

export const AppContainer = styled.div`
  display: flex;
  height: 100vh; /* Full height of the viewport */
  background-color: #141417;
  overflow: hidden; /* Prevent the main container from scrolling */
`;

export const SidebarContainer = styled.div`
  width: 68px;
  height: 93vh; /* Full height of the viewport */
  background-color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 1000px) {
    width: 100%;
    height: 10px; /* Height of the sidebar when at the bottom */
    flex-direction: row; /* Horizontal layout for icons */
    justify-content: space-around;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Shadow at the top */
    position: absolute;
    bottom: 0; /* Position at the bottom */
  }
`;

export const MainContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center; /* Center items vertically */
  margin-top: 50px; /* Add margin to accommodate the top bar */
`;

export const MainContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  background-color: #141417;
  padding: 20px;
  height: 100vh; /* Full height */
  overflow-y: auto; /* Enable vertical scrolling */
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px; /* Adjust as needed */

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const MatchesSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Heading = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

export const MatchesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  flex-grow: 1;
  width: 100%; /* Full width of the parent container */
  padding-bottom: 175px; /* Add padding to the bottom to avoid cut off content */

  @media (min-width: 1000px) {
    grid-template-columns: repeat(2, 1fr); /* Three columns for large screens */
  }

  @media (max-width: 999px) {
    grid-template-columns: repeat(2, 1fr); /* Two columns for mid-sized screens */
  }

  @media (max-width: 599px) {
    grid-template-columns: 1fr; /* Single column for smaller screens */
  }
`;

export const CouponWrapper = styled.div`
  width: 300px;
  margin-right: 10px;
  padding: 10px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const FabIcon = styled.span`
  font-size: 24px;
`;


export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  justify-content: flex-start;
`;

export const Dropdown = styled.select`
  padding: 6px 8px;
  background-color: #242429;
  color: white;
  border: 2px solid #28282E;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    border-color: #B8B8B9;
  }

  &:focus {
    outline: none;
    
  }
`;