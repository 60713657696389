// boardView.tsx
import React from 'react';
import { MainContent } from './App.css';

const BoardView: React.FC = () => {
  return (
    <MainContent>
      <h1>Board View</h1>
    </MainContent>
  );
};

export default BoardView;
