// Sidebar.css.ts
import styled from 'styled-components';

export const SidebarContainer = styled.div`
  width: 60px;
  height: 93vh; /* Full height of the viewport */
  background-color: #1B1B1F;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 50px; /* Add margin to accommodate the top bar */

  @media (max-width: 1000px) {
    width: 100%;
    height: 10px; /* Height of the sidebar when at the bottom */
    flex-direction: row; /* Horizontal layout for icons */
    justify-content: space-around;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Shadow at the top */
    position: fixed;
    bottom: 0; /* Position at the bottom */
    background-color: #141417;
    border-top: 1px solid black;
    z-index: 10; /* Set higher z-index to ensure it stays on top */
  }
`;

export const Icon = styled.div<{ active: boolean }>`
  width: 38px;
  height: 38px;
  margin: 15px 0; /* Reduce the margin to decrease spacing */
  background-color: ${({ active }) => (active ? '#0BB659' : '#555')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 06px;
  color: white;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
  
  img {
    width: 22px; /* Adjust the size of the icons as needed */
    height: 22px;
  }

  &:hover {
    background-color: ${({ active }) => (active ? '#0BB659' : '#777')};
  }

  @media (max-width: 768px) {
    margin: 0; /* Remove margin for horizontal layout */
  }
`;
