import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link
import styled from 'styled-components';
import { FaBars, FaCog, FaHeart, FaInfoCircle, FaEnvelope, FaSignal, FaBaseballBall } from 'react-icons/fa';
import { useUser } from '@clerk/clerk-react';
import LogoImage from './assets/icons/logo700.png'; // Adjust the path to your logo image

const Logo = styled.img`
  height: 40px; /* Adjust the size of the logo */
  margin-left: 15px;
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 5px 11px;
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: #141417;
  border-bottom: 1px solid #141417;
  height: 45px;
`;

const LoginButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HamburgerMenu = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  color: white;
  margin-left: 15px;
  margin-right: 25px;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 60px;
  right: 30px;
  background-color: #1B1B1F;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 1000;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #28282E;
  }

  svg {
    margin-right: 10px;
  }
`;

const SupportDropdownItem = styled(DropdownItem)`
  color: #0BB659;

  svg {
    color: #0BB659;
  }

  &:hover {
    color: #0BB659;
    background-color: #28282E;
  }
`;

const CustomSignInButton = styled.button`
  background-color: black;
  color: white;
  border: 2px solid green;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  transition: 0.4s;

  &:hover {
    background-color: #444;
  }
`;

function TopNavigation(): JSX.Element {
  const { isSignedIn } = useUser();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleSignInClick = () => {
    navigate('/sign-in');
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    closeDropdown();
  };

  return (
    <StyledHeader>
      <Link to="/"> {/* Wrap Logo in Link */}
        <Logo src={LogoImage} alt="Logo" />
      </Link>
      <LoginButtonContainer>
        {!isSignedIn && (
          <CustomSignInButton onClick={handleSignInClick}>
            Sign in/Signup
          </CustomSignInButton>
        )}
        <HamburgerMenu onClick={toggleDropdown}>
          <FaBars />
        </HamburgerMenu>
        {dropdownOpen && (
          <DropdownMenu>
            {isSignedIn && (
              <DropdownItem onClick={() => handleNavigation('/settings')}>
                <FaCog /> Settings
              </DropdownItem>
            )}
            {/* <SupportDropdownItem onClick={() => handleNavigation('/support')}>
              <FaHeart /> Support
            </SupportDropdownItem>
            <DropdownItem onClick={() => handleNavigation('/about')}>
              <FaInfoCircle /> About
            </DropdownItem>
            <DropdownItem onClick={() => handleNavigation('/contact')}>
              <FaEnvelope /> Contact
            </DropdownItem>
            <DropdownItem onClick={() => handleNavigation('/websocket-test')}>
              <FaSignal /> WebSocket Test
            </DropdownItem>
            <DropdownItem onClick={() => handleNavigation('/live-score')}>
              <FaBaseballBall /> Live Score
            </DropdownItem> */}
          </DropdownMenu>
        )}
      </LoginButtonContainer>
    </StyledHeader>
  );
}

export default TopNavigation;
