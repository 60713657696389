// UserView.css.ts
import styled from 'styled-components';

export const MainContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: center;
  color: white;
  background-color: #141417;
  padding: 20px;
  height: 100vh; /* Full height */
  overflow-y: auto; /* Enable vertical scrolling */
`;






export const UserInfo = styled.div`
  flex-grow: 1;
`;



export const UserStats = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const HamburgerMenu = styled.div`
  position: absolute;
  top: 25px;
  right: 35px;
  width: 20px;
  height: 16px;
  cursor: pointer;

  @media (max-width: 600px) {
    top: 18px;
    right: 25px;
  }

  div {
    width: 100%;
    height: 3px;
    background-color: white;
    margin: 4px 0;
  }
`;

export const PicksContainer = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: #1b1b1f;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  z-index: 1; /* Set lower z-index to ensure it goes behind the bottom bar */
  margin-bottom: 80px;

  @media (max-width: 600px) {
    padding: 10px; /* Add padding for smaller screens */
    margin-bottom: 150px; /* Adjust the margin to accommodate the bottom bar */
  }
`;

export const PicksHeader = styled.h2`
  position: absolute;
  top: 20px;
  left: 20px;
  margin: 0;
  font-size: 18px;
  color: white;

  @media (max-width: 600px) {
    top: 10px;
    left: 10px; /* Adjust position for smaller screens */
  }
`;

export const PickItem = styled.div<{ index: number }>`
  display: flex;
  align-items: center;
  padding: 10px 10px 10px;
  border-bottom: 1px solid #333;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: ${({ index }) => (index % 2 === 0 ? '#2d2d34' : '#36363e')};
  position: relative;
`;

export const TeamLogosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
`;

export const TeamLogosContainerHome = styled.div`
  
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
`;

export const TeamLogos = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0px; /* Reduced space */

  img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    object-fit: contain;
  }

  span {
    margin: 0 5px;
    font-size: 24px;
    color: white;
  }
`;

export const TeamAcronyms = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px; /* Smaller font size */
  color: #aaa;
  margin-top: 0px; /* Reduced space */
  margin-bottom: 20px; 
`;

export const PickDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
  width: 100%;
  font-weight: 500;
`;

export const PickStatus = styled.div<{ status: 'win' | 'lose' | 'neutral' }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ status }) =>
    status === 'win' ? '#04bd58' : status === 'lose' ? '#eb0000' : 'grey'};
`;

export const WeekText = styled.div`
  position: absolute;
  bottom: 5px;
  left: 10px;
  font-size: 12px;
  color: #aaa;
`;

export const FilterButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start; /* Align to the left */
  margin-bottom: 20px;
`;

export const FilterButton = styled.button<{ isSelected: boolean }>`
  border: none;
  border-radius: 20px;
  padding: 6px 15px;
  margin: 0 7px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? '#076c35' : '#555')};
  color: white;
  font-size: 16px;

  &:hover {
    background-color: #076c35;
  }

  &:focus {
    outline: none;
  }
`;

export const UserProfileContainer = styled.div`
  display: flex;
  justify-content: space-evenly; /* Ensures equal spacing between all columns */
  align-items: center;
  background-color: #28282f;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
  position: relative;

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

export const ProfileColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px; /* Adjust space between image and username */
  flex: 1; /* Ensure all columns take up equal space */
`;

export const StatsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px; /* Adjust spacing between StatValue and StatLabel */
  flex: 1; /* Ensure all columns take up equal space */
`;

export const UserProfile = styled.img`
  width: 60px; /* Smaller profile picture */
  height: 60px; /* Smaller profile picture */
  border-radius: 15%;
  object-fit: cover;
  border: 1px solid white;
`;

export const UserName = styled.h2`
  font-size: 14px; /* Smaller username text */
  margin: 0;
  color: white;
  text-align: center;
`;

export const StatValue = styled.div<{ winPct?: number }>`
  font-size: 20px;
  font-weight: bold;
  color: ${({ winPct }) =>
    winPct > 50 ? '#04bd58' : winPct < 50 ? '#eb0000' : '#ffff00'}; /* Green for >50%, Red for <50%, Yellow for =50% */
  text-align: center;
`;

export const StatLabel = styled.div`
  font-size: 12px;
  color: white;
  text-align: center;
`;

